import React, { useEffect, useState, useRef } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { TextField, Box, IconButton, Typography, Badge, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonOutline,
  CheckCircle,
  ShoppingBagOutlined,
  SearchOutlined,
  FavoriteBorder,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import { shades } from "../../theme";
import { BASE_URL } from "../../api";
import { removeFromCart, setIsCartOpen, setCount } from "../../state";
import { useAuth } from "../../AuthContext";

function Navbar({ onAuthClick, onProfileClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const { isLoggedIn } = useAuth();
  const { logout } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchIconRef = useRef(null);
  const searchBoxRef = useRef(null);
  const menuRef = useRef(null); // ref for menu

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAndRedirect = () => {
    setTimeout(() => {
      logout();
      navigate("/");
    }, 500); // Wacht 0.5s (de duur van de fade-out) voordat je navigeert
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchSubmit = () => {
    navigate(`/search?query=${searchQuery}`);
  };

  useEffect(() => {
    if (anchorEl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [anchorEl]);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.classList.add("search-open-margin");
    } else {
      document.body.classList.remove("search-open-margin");
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setIsSearchOpen(false);
  }, [navigate]);

  // Detect clicks outside of the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const removeOutOfStockItemsFromCart = async () => {
    try {
      console.log("Calling the API to get stock information for items...");
      const response = await fetch(`${BASE_URL}/api/items`);
      const itemsData = await response.json();
      console.log("Items data from API:", itemsData);
  
      for (let cartItem of cart) {
        const item = itemsData.data.find((apiItem) => apiItem.id === cartItem.id);
  
        if (item && item.attributes.stockQuantity !== null) {
          if (item.attributes.stockQuantity === 0) {
            console.log("Removing item from cart:", cartItem);
            dispatch(removeFromCart({ id: cartItem.id }));
          } else {
            // Controleer en pas de "max per order" aan
            if (item.attributes.maxPerOrder !== null && cartItem.count > item.attributes.maxPerOrder) {
              console.log("Adjusting item count in cart to max per order:", cartItem);
              dispatch(setCount({ id: cartItem.id, count: item.attributes.maxPerOrder }));
            } else if (cartItem.count > item.attributes.stockQuantity) {
              console.log("Adjusting item count in cart to stock quantity:", cartItem);
              dispatch(setCount({ id: cartItem.id, count: item.attributes.stockQuantity }));
            }
          }
        }
      }
    } catch (error) {
      console.error("Error processing stock information:", error);
    }
  };

  const handleCartClick = () => {
    removeOutOfStockItemsFromCart();
    dispatch(setIsCartOpen({}));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="60px"
      backgroundColor="rgba(255, 255, 255, 0.95)"
      color="black"
      position="fixed"
      top="0"
      left="0"
      zIndex="1"
    >
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          onClick={() => navigate("/")}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <Link to="/">
            <HomeOutlinedIcon
              className="vuurwerk-bruchem-icon"
              sx={{ fontSize: "22px", verticalAlign: "middle", color: "black" }}
            />
          </Link>
          <Typography
            variant="h3title"
            color={shades.secondary[500]}
            className="vuurwerk-bruchem-text"
          >
            <r>VUURWERK BRUCHEM</r>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          columnGap="20px"
          zIndex="2"
        >
          <Box>
            <IconButton
              ref={searchIconRef}
              sx={{ color: "black" }}
              onClick={toggleSearch}
            >
              <SearchOutlined />
            </IconButton>
          </Box>
          <IconButton
            onClick={() => navigate("/favorites")}
            sx={{ color: "black" }}
          >
            <FavoriteBorder />
          </IconButton>
          {isLoggedIn ? (
            <>
              <IconButton
                sx={{ color: "black", position: "relative" }}
                onClick={handleClick}
              >
                <PersonOutline />
                <CheckCircle
                  style={{ color: shades.green }}
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 12,
                    height: 12,
                  }} />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    position: 'absolute',
                    top: '100%', // Positioneer het net onder de knop
                    left: '0',
                    zIndex: 1000, // Zorg dat het menu boven andere elementen zweeft
                  },
                }}
                ref={menuRef} // Voeg ref toe aan het menu
              >
                <MenuItem onClick={() => { navigate("/orders"); handleClose(); }}>Mijn bestellingen</MenuItem>
                <MenuItem onClick={() => { navigate("/profile"); handleClose(); }}>Mijn profiel</MenuItem>
                <MenuItem onClick={() => { logoutAndRedirect(); handleClose(); }}>Uitloggen</MenuItem>
              </Menu>
            </>
          ) : (
            <IconButton
              sx={{ color: "black", position: "relative" }}
              onClick={onAuthClick}
            >
              <PersonOutline />
            </IconButton>
          )}
          <Badge
            badgeContent={cart.length}
            color="secondary"
            invisible={cart.length === 0}
            sx={{
              "& .MuiBadge-badge": {
                right: 5,
                top: 5,
                padding: "0 4px",
                height: "14px",
                minWidth: "13px",
              },
            }}
          >
            <IconButton onClick={handleCartClick} sx={{ color: "black" }}>
              <ShoppingBagOutlined />
            </IconButton>
          </Badge>
        </Box>
      </Box>
      {isSearchOpen && (
        <Box ref={searchBoxRef} className="customSearchBox">
          <TextField
            className="customSearchField"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchSubmit();
              }
            }}
            autoFocus
          />
          <IconButton onClick={handleSearchSubmit} sx={{ color: "black" }}>
            <SearchOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default Navbar;
