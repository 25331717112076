import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useMediaQuery, useTheme, Grid } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check'; // Nieuwe import voor het vinkje
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_URL } from "../../api";


const API_BASE_URL = BASE_URL;
const gridItemStyle = {
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'center',  // Voeg deze regel toe om de inhoud te centreren
  fontFamily: "'Oxanium', sans-serif",
  fontSize: '0.8rem',
  color: "white",
};
export default function MainCarousel() {
  const [carouselData, setCarouselData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/carousels?populate=image`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Error fetching carousels");
        }
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setCarouselData(data.data);
        } else {
          console.error("Invalid data from server:", data);  
        }
      })
      .catch(err => console.error(err));
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '64px' }}>
      <Carousel
        showArrows={true}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        stopOnHover
        showStatus={false}
        interval={5000}
      >
        {carouselData.map((item) => (
          <Box
            key={item.id}
            style={{
              backgroundImage: `url(${API_BASE_URL}${item.attributes.image.data.attributes.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
<Box
  bgcolor="rgba(0, 0, 0, 0.5)"
  p={4}
  borderRadius={1}
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }}
>
  <Typography
    sx={{
      textAlign: "left",
      fontFamily: "Oxanium, sans-serif",
      color: "white",
      fontSize: "5.5rem",
      fontWeight: "bold",
      margin: 0, // Zorg voor een consistente marge
      padding: 0, // Zorg voor een consistente padding
    }}
    gutterBottom
  >
    {item.attributes.bigText}
  </Typography>
  <Typography
    sx={{
      textAlign: "left",
      fontFamily: "'Oxanium', sans-serif",
      color: "white",
      fontSize: "1.5rem",
      margin: 0, // Zorg voor een consistente marge
      padding: 0, // Zorg voor een consistente padding
      marginTop: "1rem", // Voeg een specifieke bovenmarge toe indien nodig
    }}
  >
    {item.attributes.title}
  </Typography>
</Box>

            <Box
  sx={{
    alignSelf: "flex-start",
    p: 4,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: '100%', // Set the width to 100%
    display: 'flex', // Use flexbox
    alignItems: 'flex-start' // Align items to the start (left)
  }}
>
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#e3197e",
      borderRadius: "20px",
      padding: '10px 20px',
      fontSize: '1.2rem',
      '&:hover': {
        backgroundColor: "#c71585",
      },
      margin: '10px 20px 10px 10px',
    }}
    onClick={() => window.location.href = item.attributes.callToAction}
  >
    {item.attributes.buttonText}
  </Button>
</Box>

          </Box>
        ))}
      </Carousel>

        {/* Roze balk met tekst en vinkjes onder de carrousel */}
        <Box sx={{
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: '#e3197e', // Roze achtergrondkleur
        color: 'white',
        padding: '1%', // Aanpassen voor exacte hoogte
        marginTop: '10', // Aangepast om witruimte te verwijderen
      }}>
<Grid container spacing={2}>
  <Grid item xs={3} style={gridItemStyle}>
    <CheckIcon /> Grote winkel met ruime parkeergelegenheid
  </Grid>
  <Grid item xs={3} style={gridItemStyle}>
    <CheckIcon /> Het beste vuurwerk voor knallende prijzen
  </Grid>
  <Grid item xs={3} style={gridItemStyle}>
    <CheckIcon /> Voor liefhebbers, door liefhebbers
  </Grid>
  <Grid item xs={3} style={gridItemStyle}>
    <CheckIcon /> Het beste vuurwerk van de Bommelerwaard!
  </Grid>
</Grid>
      </Box>
    </div>
  );
}