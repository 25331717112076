import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

 
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { BASE_URL } from "../../api";
import { fetcher2 } from '../../api';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Checkout = () => {
  const { userData } = useAuth();
  const [discountCode, setDiscountCode] = useState('');
  const [discount, setDiscount] = useState({ type: '', value: 0 });
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState("");
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [email, setEmail] = useState(userData?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  const [streetName, setStreetName] = useState(userData?.streetName || "");
  const [houseNumber, setHouseNumber] = useState(userData?.houseNumber || "");
  const [postalCode, setPostalCode] = useState(userData?.postalCode || "");
  const [houseNumberSuffix, setHouseNumberSuffix] = useState(userData?.houseNumberSuffix || "");
  const [city, setCity] = useState(userData?.city || "");
  const [pickupDate, setPickupDate] = useState("");
  const [tosAgreed, setTosAgreed] = useState(false);
  const [pickupTime, setPickupTime] = useState("");
  const cart = useSelector((state) => state.cart.cart);
  const [totalPrice, setTotalPrice] = useState(0);
  const [originalTotalPrice, setOriginalTotalPrice] = useState(0);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [errors, setErrors] = useState({}); // Nieuwe state voor foutmeldingen
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false);
  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
};
  const generatePickupTimes = () => {
    const times = [];
    const startHour = pickupDate === "Dinsdag 31-12-2024" ? 9 : (pickupDate === "Zaterdag 28-12-2024" ? 7 : 9);
    const endHour = pickupDate === "Dinsdag 31-12-2024" ? 17 : (pickupDate === "Zaterdag 28-12-2024" ? 9 : 21);
    

    for (let hour = startHour; hour <= endHour; hour++) {
      times.push(`${hour.toString().padStart(2, "0")}:00`);
    }

    return times;
  };
  const toggleDiscountCodeInput = () => {
    setShowDiscountCodeInput(!showDiscountCodeInput);
  };
  const validate = () => {
    let tempErrors = {};
    tempErrors.firstName = firstName ? "" : "Voornaam is verplicht";
    tempErrors.lastName = lastName ? "" : "Achternaam is verplicht";
    tempErrors.email = email
      ? /\S+@\S+\.\S+/.test(email)
        ? ""
        : "Ongeldig e-mailadres"
      : "E-mail is verplicht";
    tempErrors.phoneNumber = phoneNumber
      ? /^\+?[0-9]+$/.test(phoneNumber)
        ? ""
        : "Ongeldig telefoonnummer"
      : "Telefoonnummer is verplicht";
    tempErrors.streetName = streetName ? "" : "Straatnaam is verplicht";
    tempErrors.city = city ? "" : "Woonplaats is verplicht";
    tempErrors.postalCode = postalCode
      ? /^\d{4}\s?[a-zA-Z]{2}$/.test(postalCode)
        ? ""
        : "Postcode moet 4 cijfers en 2 letters bevatten"
      : "Postcode is verplicht";
    tempErrors.houseNumber = houseNumber
      ? /^\d+$/.test(houseNumber)
        ? ""
        : "Huisnummer mag alleen nummers bevatten. Gebruik 'Toevoeging huisnummer' voor extra tekens"
      : "Huisnummer is verplicht";
    tempErrors.pickupDate = pickupDate ? "" : "Ophaaldatum is verplicht";
    tempErrors.pickupTime = pickupTime ? "" : "Afhaaltijd is verplicht";
    tempErrors.tosAgreed = tosAgreed
      ? ""
      : "Je moet akkoord gaan met de algemene voorwaarden en privacy policy";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const handleConfirmOrder = async () => {
    if (isSubmitting) return; // Voorkom meerdere inzendingen

    if (!validate()) return; // Valideer inputvelden

    setIsSubmitting(true); // Start het indieningsproces

    // Genereer een uniek bestelnummer
    const orderNumber = (() => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const milliseconds = String(now.getMilliseconds()).padStart(3, "0").substr(0, 2);
        const randomTwoDigits = String(Math.floor(Math.random() * 100)).padStart(2, "0");
        return `${day}${milliseconds}${randomTwoDigits}`;
    })();

    // Formatteer notities voor de bestelling
    const formattedNotes = notes.replace(/\n/g, "<br>");

    const requestBody = {
        tosAgreed: tosAgreed,
        email: email,
        phoneNumber: phoneNumber,
        userName: `${firstName} ${lastName}`,
        webshopOrderNumber: orderNumber,
        products: cart.map(({ id, count }) => ({
            id,
            count,
        })),
        notes: formattedNotes,
        streetName: streetName,
        houseNumber: houseNumber,
        houseNumberSuffix: houseNumberSuffix || undefined,
        postalCode: postalCode,
        pickupDate: pickupDate,
        pickupTime: pickupTime,
        city: city,
        discountCode: discountCode,
        paymentStatus: "open",
    };

    try {
        // Pas hier je API-endpoint pad aan indien nodig
        const jsonResponse = await fetcher2('webshop-orders', 'POST', {data: requestBody});

        // Controleer of de respons een betalings-URL bevat en navigeer daarheen
        if (jsonResponse && jsonResponse.paymentUrl) {
            window.location.href = jsonResponse.paymentUrl;
        } else {
            console.error("Geen betalingslink ontvangen");
        }
    } catch (error) {
        console.error("Er was een fout tijdens het verwerken van de bestelling", error);
    } finally {
        setIsSubmitting(false); // Reset indieningsstatus
    }
};

const toggleOrderDetails = () => {
    setShowOrderDetails(!showOrderDetails);
  };
  const handleSubmitDiscountCode = async () => {
    try {
        const endpoint = `discount-codes?filters[code]=${discountCode}`;
        const data = await fetcher2(endpoint);
        // De rest van je logica blijft hetzelfde, aangezien de response structuur hetzelfde zou moeten zijn.
        if (data.data && data.data.length > 0) {
          const discountCodeData = data.data[0].attributes;

          // Controleer de geldigheid van de kortingscode
          const currentDate = new Date();
          const endDate = new Date(discountCodeData.endDate);

          if (currentDate <= endDate) {
              if (discountCodeData.discountType === 'discountPercentage') {
                setDiscount({ type: 'discountPercentage', value: discountCodeData.value });
              } else if (discountCodeData.discountType === 'freeProduct') {
                  // Voeg het gratis product toe aan de cart
              }
          } else {
              alert('Kortingscode is verlopen.');
          }
        } else {
            alert('Kortingscode is niet geldig.');
        }
    } catch (error) {
        console.error('Fout bij het valideren van de kortingscode', error);
    }
};
 




useEffect(() => {
  let calculatedTotalPrice = cart.reduce((total, item) => {
    const productPrice = item.attributes.priceDiscount
      ? item.attributes.priceDiscount
      : item.attributes.price;
    return total + item.count * productPrice;
  }, 0);

  setOriginalTotalPrice(parseFloat(calculatedTotalPrice.toFixed(2)));
  if (discount.type === 'discountPercentage') {
    calculatedTotalPrice *= (1 - (discount.value / 100));
  }

  setTotalPrice(parseFloat(calculatedTotalPrice.toFixed(2)));
}, [cart, discount]); // Deze useEffect wordt getriggerd wanneer 'cart' of 'discount' verandert



  return (
    <Box width="80%" m="100px auto">
      <Grid container spacing={4}>
  {/* Linkerkant: Winkelwagenitems */}
  <Grid item xs={12} md={6} sx={{ pr: 3 }}>
    <Typography variant="h5" gutterBottom>Uw Bestelling</Typography>
    <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}>
    <Card variant="outlined">
      <CardActions>
        <IconButton onClick={toggleOrderDetails} size="small">
          {showOrderDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <Typography component="span">Bestelling Details</Typography>
      </CardActions>
      {showOrderDetails && (
        <CardContent>
          {cart.map((item) => (
            <Box key={`${item.attributes.name}-${item.id}`}>
              <FlexBox p="15px 0">
                <Box flex="1 1 20%">
                  <img
                    alt={item?.attributes?.name}
                    width="60%"
                    height="auto"
                    src={`${BASE_URL}${
                      item?.attributes?.image?.data?.attributes?.formats
                        ?.medium?.url ||
                      item?.attributes?.image?.data?.attributes?.url
                    }`}
                    style={{ objectFit: "contain" }}
                  />
                </Box>
                <Box flex="1 1 80%">
                  <FlexBox mb="5px">
                    <Typography fontWeight="bold">
                      {item.attributes.name}
                    </Typography>
                  </FlexBox>
                  <Typography>{item.attributes.shortDescription}</Typography>
                  <FlexBox m="15px 0">
                    <Typography>{item.count} stuks </Typography>
                    <Typography fontWeight="bold">
                      €{(item.count * (item.attributes.priceDiscount || item.attributes.price)).toFixed(2)}
                    </Typography>
                  </FlexBox>
                </Box>
              </FlexBox>
              <Divider />
            </Box>
          ))}<Box display="flex" justifyContent="flex-end" width="100%">

        </Box>
        </CardContent>
      )}          <FlexBox justifyContent="space-between" width="100%" p={2}>
<Typography variant="h6">Totaalprijs:</Typography>
<Box display="flex" alignItems="center">
  {discount.type === 'discountPercentage' && originalTotalPrice > totalPrice && (
    <Typography variant="h6" component="span" sx={{ textDecoration: 'line-through', mr: 1 }}>
      €{originalTotalPrice.toFixed(2)}
    </Typography>
  )}
  <Typography variant="h6" fontWeight="bold">
    €{totalPrice.toFixed(2)}
  </Typography>
</Box>


    </FlexBox>
    </Card>
    </Box>
    
  </Grid>
  {/* ... Rechterkant: Formuliervelden ... */}
        <Grid item xs={12} md={6}>
  <Typography variant="h5" gutterBottom>Uw Gegevens</Typography>
  <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)", pl: 3, pr: 3 }}>
 

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.firstName)}
                name="firstName"
                autoComplete="given-name"
                helperText={errors.firstName}
                label="Voornaam"
                fullWidth
                margin="normal"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.lastName)}
                name="lastName"
                autoComplete="family-name"
                helperText={errors.lastName}
                label="Achternaam"
                fullWidth
                margin="normal"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.email)}
                name="email"
                autoComplete="email"
                helperText={errors.email}
                label="E-mail"
                fullWidth
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.phoneNumber)}
                name="tel"
                autoComplete="tel"
                helperText={errors.phoneNumber}
                label="Telefoonnummer"
                fullWidth
                margin="normal"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.streetName)}
                helperText={errors.streetName}
                name="street-address"
                autoComplete="street-address"
                label="Straatnaam"
                fullWidth
                margin="normal"
                variant="outlined"
                value={streetName}
                onChange={(e) => setStreetName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                error={Boolean(errors.houseNumber)}
                helperText={errors.houseNumber}
                label="Huisnummer"
                name="house-number"
                autoComplete="address-line2"
                fullWidth
                margin="normal"
                variant="outlined"
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Toevoeging"
                name="house-number-suffix"
                autoComplete="address-line3"
                fullWidth
                margin="normal"
                variant="outlined"
                value={houseNumberSuffix}
                onChange={(e) => setHouseNumberSuffix(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                error={Boolean(errors.postalCode)}
                helperText={errors.postalCode}
                name="postal-code"
                autoComplete="postal-code"
                label="Postcode"
                fullWidth
                margin="normal"
                variant="outlined"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={Boolean(errors.city)}
                helperText={errors.city}
                name="address-level2"
                autoComplete="address-level2"
                label="Woonplaats"
                fullWidth
                margin="normal"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Ophaaldatum</InputLabel>
                <Select
                  value={pickupDate}
                  label="Ophaaldatum"
                  onChange={(e) => setPickupDate(e.target.value)}
                  error={Boolean(errors.pickupDate)}
                >
                  <MenuItem value="Zaterdag 28-12-2024">
                    Zaterdag 28-12-2024
                  </MenuItem>
                  {/* <MenuItem value="Maandag 30-12-2024">
                    Maandag 30-12-2024
                  </MenuItem>
                  <MenuItem value="Dinsdag 31-12-2024">
                    Dinsdag 31-12-2024
                  </MenuItem> */}
                </Select>

              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Afhaaltijd</InputLabel>
                <Select
                  value={pickupTime}
                  label="Afhaaltijd"
                  onChange={(e) => setPickupTime(e.target.value)}
                  error={Boolean(errors.pickupTime)}
                >
                  {generatePickupTimes().map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                checked={tosAgreed}
                onChange={(e) => setTosAgreed(e.target.checked)}
                name="tosAgreed"
                color="primary"
              />
            }
            label={
              <Typography>
                Ik ga akkoord met de{" "}
                <a
                  href="https://vuurwerkbruchem.com/verkoopvoorwaarden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  algemene voorwaarden
                </a>{" "}
                en{" "}
                <a
                  href="https://vuurwerkbruchem.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                .
              </Typography>
            }
          />
          <TextField
            label="Type hier eventuele bijzonderheden"
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
         <Box>
            <Button onClick={toggleDiscountCodeInput}>
              Klik hier om een kortingscode te verzilveren
            </Button>
            {showDiscountCodeInput && (
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Kortingscode"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSubmitDiscountCode}>
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          <Button
            variant="contained"
            disabled={isSubmitting || cart.length === 0}
            onClick={handleConfirmOrder}
          >
            Naar betalen
          </Button>
          </Box>
</Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
