import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, useMediaQuery } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { BASE_URL } from "../../api";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../../state";
import Item from "../../components/Item";
import { useLocation } from "react-router-dom";

const ShoppingList = () => {


  function format_category_name(category) {
    // Voeg een spatie toe vóór elke hoofdletter die volgt op een kleine letter.
    let spacedString = category.replace(/(?<=[a-z])([A-Z])/g, " $1");

    // Voeg spaties toe vóór en na cijferreeksen.
    spacedString = spacedString.replace(
      /(?<=[a-zA-Z])(\d+)|(\d+)(?=[a-zA-Z])/g,
      " $1$2 "
    );

    const splitWords = spacedString.trim().split(/\s+/);

    return splitWords
      .map((word) => {
        if (!isNaN(word)) return word; // Als het een cijfer is, retourneer het zoals het is
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Anders, formatteer het woord
      })
      .join(" ");
  }

  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);

  const breakPoint = useMediaQuery("(min-width:600px)");

  const categories = [
    "Alles",
    ...Array.from(new Set(items.map((item) => item.attributes.category))),
  ];

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryFromURL = params.get("category");

  const [value, setValue] = useState(categoryFromURL || "Alles");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getItems() {
      const response = await fetch(`${BASE_URL}/api/items?populate=image`);
      const data = await response.json();
      dispatch(setItems(data.data));
      setLoading(false);
    }

    getItems();
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <Typography variant="h5" align="center">
        Laden...
      </Typography>
    );
  }

  return (
    <Box
      width="80%"
      margin={{ xs: "80px auto", sm: "20px auto" }}
      className="shift-on-search-mobile"
    >
      <Typography variant="h3" textAlign="center">
        De laatste producten nu tegen de scherpste prijzen! <b>OP = OP!</b>
      </Typography>
      {breakPoint ? (
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{ sx: { display: breakPoint ? "block" : "none" } }}
          sx={{
            m: "25px",
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
        >
          {categories.map((category) => (
            <Tab
              label={format_category_name(category)}
              value={category}
              key={category}
            />
          ))}
        </Tabs>
      ) : (
        <Select
          value={value}
          onChange={(e) => handleChange(null, e.target.value)}
          sx={{ width: "100%", marginBottom: "25px" }}
        >
          {categories.map((category) => (
            <MenuItem value={category} key={category}>
              {format_category_name(category)}
            </MenuItem>
          ))}
        </Select>
      )}

<Box
  margin="0 auto"
  display="grid"
  gridTemplateColumns="repeat(auto-fill, 300px)"
  justifyContent="space-around"
  rowGap="20px"
  columnGap="1.33%"
>
  {Array.isArray(items) &&
    items.map((item) =>
      value === item.attributes.category || value === "Alles" ? (
        <Item item={item} key={`${item.name}-${item.id}`} />
      ) : null
    )}
</Box>

    </Box>
  );
};

export default ShoppingList;
