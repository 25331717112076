import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import { BASE_URL } from "../api";
import { fetchOrdersForUser } from "../api";

const ProfilePage = () => {
  const { userData, logout } = useAuth();
  const [updatedData, setUpdatedData] = useState(userData);
  const navigate = useNavigate();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [userOrders, setUserOrders] = useState([]);
  const openPasswordModal = () => setIsPasswordModalOpen(true);
  const closePasswordModal = () => setIsPasswordModalOpen(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    setUpdatedData(userData);
  }, [userData]);

  useEffect(() => {
    if (userData) {
      fetchOrdersForUser(userData.email)
        .then((orders) => setUserOrders(orders.data))
        .catch((error) => console.error("Error fetching orders:", error));
    }
  }, [userData]);

  if (!updatedData) {
    return null;
  }
  const logoutAndRedirect = () => {
    setIsFadingOut(true); // Start de fade-out
    setTimeout(() => {
      logout();
      navigate("/");
    }, 500); // Wacht 0.5s (de duur van de fade-out) voordat je navigeert
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const displayedPhoneNumber = updatedData.phoneNumber.replace("+31", "0");
    const fullPhoneNumber = `+31${displayedPhoneNumber.slice(1)}`;

    const dataToSubmit = {
      ...updatedData,
      phoneNumber: fullPhoneNumber,
    };

    try {
      const response = await fetch(`${BASE_URL}/api/users/${userData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(
          responseData.message ||
            "Er ging iets mis bij het bijwerken van de gebruiker."
        );
      }

      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Er ging iets mis:", error.message);
      alert(error.message);
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    navigate("/");
    window.location.reload();
  };
  return (
    <div className={`profile-wrapper ${isFadingOut ? "fade-out" : ""}`}>
      <Container
        component="main"
        maxWidth="md"
        style={{ marginTop: "64px" }}
        className="shift-on-search"
      >
        <div className="paper">
          {/* <Avatar className="avatar">
            Je kunt hier de avatar van de gebruiker weergeven 
          </Avatar>*/}
        <Typography variant="h5" component="div">
            Profiel bewerken
          </Typography>
          <form className="form" onSubmit={handleSubmit}>
            {/* Gebruikersnaam en E-mail */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mailadres"
              name="email"
              value={updatedData.email}
              onChange={handleInputChange}
            />

            {/* Voornaam en Achternaam */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="Voornaam"
                  name="firstName"
                  value={updatedData.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Achternaam"
                  name="lastName"
                  value={updatedData.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            {/* Straat, Huisnummer en Toevoeging */}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="streetName"
                  label="Straatnaam"
                  name="streetName"
                  value={updatedData.streetName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="houseNumber"
                  label="Nr."
                  name="houseNumber"
                  value={updatedData.houseNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="houseNumberSuffix"
                  label="Toevoeging"
                  name="houseNumberSuffix"
                  value={updatedData.houseNumberSuffix}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            {/* Postcode en Woonplaats */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="postalCode"
                  label="Postcode"
                  name="postalCode"
                  value={updatedData.postalCode}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="city"
                  label="Woonplaats"
                  name="city"
                  value={updatedData.city}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            {/* Land en Telefoonnummer */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  id="country"
                  label="Land"
                  defaultValue="Nederland"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Telefoonnummer"
                  name="phoneNumber"
                  value={updatedData.phoneNumber}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            {/* Knoppen voor bijwerken, wachtwoord wijzigen en uitloggen */}
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className="submit"
                  onClick={handleSubmit}
                >
                  Bijwerken
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openPasswordModal}
                >
                  Wachtwoord wijzigen
                </Button>
              </Grid>
              <Grid item>
              <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "16px" }}
                  onClick={() => {
                    navigate("/orders"); // Navigeer naar de "/orders" pagina bij klik
                  }}
                >
                  Mijn Bestellingen
                </Button>
              </Grid>
              <Grid item>
              <Button
                  variant="contained"
                  color="secondary"
                  className="submit"
                  onClick={logoutAndRedirect}
                >
                  Uitloggen
                </Button>
               

              </Grid>
            </Grid>
          </form>


          <ChangePasswordModal
            isOpen={isPasswordModalOpen}
            onClose={closePasswordModal}
            userData={userData}
          />

          <Dialog
            open={isSuccessModalOpen}
            onClose={handleCloseSuccessModal}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle style={{ textAlign: "center" }}>Gelukt!</DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ marginTop: "1rem" }}>
                Je gegevens zijn succesvol bijgewerkt!
              </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleCloseSuccessModal} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </div>
  );
};

export default ProfilePage;
