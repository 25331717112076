import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../../state";
import Item from "../../components/Item";
import { useLocation } from "react-router-dom";

import { BASE_URL } from "../../api";
const API_BASE_URL = BASE_URL;
const ShoppingList2 = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);
  const breakPoint = useMediaQuery("(min-width:600px)");

  // Extract unique categories from items and add "Alles" at the beginning
  const categories = [
    "Alles",
    ...Array.from(new Set(items.map((item) => item.attributes.category))),
  ];

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryFromURL = params.get("category");

  const [value, setValue] = useState(categoryFromURL || "Alles");

  useEffect(() => {
    async function getItems() {
      const response = await fetch(`${BASE_URL}/api/items?populate=image&publicationState=preview`);
      const data = await response.json();
      dispatch(setItems(data.data));
    }

    getItems();
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width="80%" margin="80px auto">
      <Typography variant="h3" textAlign="center">
        Het beste <b>Vuurwerk</b> 2024 assortiment
      </Typography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        centered
        TabIndicatorProps={{ sx: { display: breakPoint ? "block" : "none" } }}
        sx={{
          m: "25px",
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
        }}
      >
        {categories.map((category) => (
          <Tab label={category.toUpperCase()} value={category} key={category} />
        ))}
      </Tabs>
      <Box
        margin="0 auto"
        display="grid"
        gridTemplateColumns="repeat(auto-fill, 300px)"
        justifyContent="space-around"
        rowGap="20px"
        columnGap="1.33%"
      >
        {Array.isArray(items) &&
          items.map((item) =>
            value === item.attributes.category || value === "Alles" ? (
              <Item item={item} key={`${item.name}-${item.id}`} />
            ) : null
          )}
      </Box>
    </Box>
  );
};

export default ShoppingList2;
