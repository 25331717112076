import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Item from "../../components/Item";
import { BASE_URL } from "../../api";
import { fetcher2 } from '../../api'; 
const API_BASE_URL = BASE_URL;

const Favorites = () => {
  const [favoriteItems, setFavoriteItems] = useState([]);

  useEffect(() => {
    fetchFavoriteItems();
  }, []);

  const fetchFavoriteItems = async () => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
  
    if (Array.isArray(storedFavorites) && storedFavorites.length > 0) {
      try {
        const data = await Promise.all(
          storedFavorites.map((id) => fetcher2(`items/${id}?populate=image`))
        );
  
        setFavoriteItems(data.filter(Boolean).map((d) => d.data));
      } catch (error) {
        console.error("Error fetching favorite items:", error);
      }
    } else {
      setFavoriteItems([]);
    }
  };

  const removeFromFavorites = (id) => {
    const updatedFavorites = favoriteItems.filter((item) => item.id !== id);
    localStorage.setItem(
      "favorites",
      JSON.stringify(updatedFavorites.map((item) => item.id))
    );
    setFavoriteItems(updatedFavorites);
  };

  return (
    <Box width="80%" m="80px auto" className="shift-on-search">
      <Typography variant="h3" mb={4}>
        Mijn favorieten
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
  {favoriteItems && favoriteItems.length > 0 ? (
    favoriteItems.map((item) => (
      <Box
        key={item.id}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Item item={item} />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => removeFromFavorites(item.id)}
          sx={{
            backgroundColor: "#222222",
            color: "white",
            borderRadius: 0,
            minWidth: "150px",
            padding: "10px 40px",
          }}
        >
          Verwijderen van favorieten
        </Button>
      </Box>
    ))
  ) : (
    <Typography variant="body1">Geen favorieten gevonden.</Typography>
  )}
</Box>
    </Box>
  );
};

export default Favorites;
