import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../api";
import { useDispatch } from "react-redux";
import { clearCart } from "../../state";
import { fetcher2 } from '../../api'; // Zorg dat het pad klopt
const API_BASE_URL = BASE_URL;

const handlePrint = () => {
  const printContents = document.getElementById("printArea").innerHTML;

  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);

  iframe.contentDocument.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          // Print styles 
        </style>
      </head>
      <body>${printContents}</body>
    </html>
  `);

  iframe.contentDocument.close();
  iframe.contentWindow.print();

  iframe.parentNode.removeChild(iframe);
};
const fetchFreeItems = async () => {
  try {
    const data = await fetcher2("free-items");
    // Map over de 'data' array en haal de 'attributes' van elk item op
    return data.data.map(item => item.attributes);
  } catch (error) {
    console.error("Error fetching free items:", error);
  }
};

const determineFreeItems = (totalPrice, freeItems) => {
  const eligibleFreeItems = freeItems.filter(item => totalPrice >= item.freeFromPrice);
    if (eligibleFreeItems.length === 0) return [];

  // Sorteer items op 'freeFromPrice' in aflopende volgorde
  eligibleFreeItems.sort((a, b) => b.freeFromPrice - a.freeFromPrice);

  // Voeg altijd het item met 'freeFromPrice' = 0 toe
  const alwaysFreeItem = freeItems.find(item => item.freeFromPrice === 0);

  // Voeg het item met de hoogste 'freeFromPrice' toe dat nog steeds lager is dan de 'totalPrice'
  const highestEligibleItem = eligibleFreeItems[0];

  return [alwaysFreeItem, highestEligibleItem].filter(Boolean); // Verwijder undefined waarden
};
const Confirmation = () => {
  const removeDuplicateRows = (products) => {
    const uniqueProducts = [];
    const seenArtNumbers = new Set();
  
    products.forEach((product) => {
      if (!seenArtNumbers.has(product.artNumber)) {
        seenArtNumbers.add(product.artNumber);
        uniqueProducts.push(product);
      }
    });
  
    return uniqueProducts;
  };
  const [freeItemsAdded, setFreeItemsAdded] = useState(false);
  // Clean up cookies
  const clearCookiesExcept = (exceptions) => {
    document.cookie.split(";").forEach((c) => {
      const key = c.split("=")[0].trim();
      if (!exceptions.includes(key)) {
        document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    });
  };
  
  // Clean up storage
  const clearStorageExcept = (exceptions, storageType) => {
    Object.keys(storageType).forEach((key) => {
      if (!exceptions.includes(key)) {
        storageType.removeItem(key);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      clearCookiesExcept(['favorites', 'jwt', 'cookiesAccepted']);
      clearStorageExcept(['favorites', 'jwt', 'cookiesAccepted'], localStorage);
      clearStorageExcept(['favorites', 'jwt', 'cookiesAccepted'], sessionStorage);
    });
  }, []);

  const [webshopOrder, setWebshopOrder] = useState(null);
  const { webshopOrderNumber } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (webshopOrderNumber && webshopOrderNumber.startsWith("cs_")) {
      navigate("/checkout/success2/" + webshopOrderNumber);
    }
  }, [webshopOrderNumber, navigate]);

  useEffect(() => {
    fetcher2(`webshop-orders?filters[webshopOrderNumber][$eq]=${webshopOrderNumber}`)
      .then(data => {
        setWebshopOrder(data.data[0]);
  
        // Voeg deze nieuwe logica hier toe
        fetchFreeItems().then(freeItems => {
          if (!data.data[0] || freeItemsAdded) return; // Controleer of gratis items al zijn toegevoegd
    
  
          const freeProductsToAdd = determineFreeItems(data.data[0].attributes.totalPrice, freeItems).map(item => ({
            ...item,
            price: 0, // Zet de prijs op 0
            count: 1, // Stel een standaard hoeveelheid in
          }));
          setFreeItemsAdded(true);
          setWebshopOrder(prevOrder => ({
            ...prevOrder,
            attributes: {
              ...prevOrder.attributes,
              products: [...prevOrder.attributes.products, ...freeProductsToAdd]
            }
          }));
        });
      })
      .catch(err => console.error(err));
  }, [webshopOrderNumber]);

  const dispatch = useDispatch();



  useEffect(() => {
    if (!webshopOrder) return;

    dispatch(clearCart());

      const totalAmount = webshopOrder.attributes.totalPrice;



  }, [webshopOrder]);

  if (!webshopOrder) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      className="shift-on-search"
      m="80px auto"
      width="80%"
      height="100%"
      p={3}
      bgcolor="white"
    >
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je bestelling is succesvol ontvangen, dankjewel! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>

      <Box
        id="printArea"
        m="20px auto"
        width="210mm"
        height="297mm"
        border="1px solid black"
        p={3}
        bgcolor="white"
      >
        <Box display="flex" justifyContent="flex-start">
          <span className="MuiTypography-root MuiTypography-h3title vuurwerk-bruchem-text css-w65vrb-MuiTypography-root">
            <b>VUURWERK BRUCHEM</b>
          </span>
        </Box>

        <Typography align="left" gutterBottom>
          Dorpsstraat 20
        </Typography>
        <Typography align="left" gutterBottom>
          {" "}
          5314 AE Bruchem
        </Typography>
        <Typography align="left" gutterBottom>
          0418 700 511
        </Typography>
        <Typography align="left" gutterBottom>
          VUURWERKBRUCHEM.NL
        </Typography>

        <hr style={{ margin: "20px 0" }} />

        <Box my={3}>
          <Typography>
            <strong>Bestelnummer:</strong> {webshopOrder.id}
          </Typography>
          <Typography>
            <strong>Naam:</strong> {webshopOrder.attributes.userName}  
          </Typography>
          <Typography>
            <strong>Email:</strong> {webshopOrder.attributes.email}
          </Typography>
          <Typography>
            <strong>Telefoon:</strong> {webshopOrder.attributes.phoneNumber}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Afhaalgegevens
        </Typography>

        <Typography>
          <strong>Datum:</strong> {webshopOrder.attributes.pickupDate}
        </Typography>
        <Typography>
          <strong>Tijd:</strong> {webshopOrder.attributes.pickupTime} 
        </Typography>
        <Typography>
          <strong>Status:</strong> {webshopOrder.attributes.paymentStatus}
        </Typography>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Producten
          </Typography>

          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>Art. Nummer</th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>Naam</th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>Prijs</th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>Aantal</th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>Subtotaal</th>
              </tr>
            </thead>
            <tbody>
  {removeDuplicateRows(webshopOrder.attributes.products).map(product => (
    <tr key={product.id}>
      <td style={{ paddingBottom: "8px" }}>{product.artNumber}</td>
      <td style={{ paddingBottom: "8px" }}>{product.name}</td>
      <td style={{ paddingBottom: "8px" }}>
        €{product.price ? product.price.toFixed(2) : "0.00"}
      </td>
      <td style={{ paddingBottom: "8px" }}>{product.count}</td>
      <td style={{ paddingBottom: "8px" }}>
        €{(product.price * product.count).toFixed(2)} 
      </td>
    </tr>
  ))}
</tbody>
            <tfoot>
              <tr>
                <td colSpan="4" style={{ paddingTop: "10px" }}>
                  <strong>Totaal:</strong>
                </td>
                <td style={{ paddingTop: "10px" }}>
                  <strong>
                    €{webshopOrder.attributes.products
                      .reduce((acc, product) => acc + product.price * product.count, 0)
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </Box>

        <Typography align="center" gutterBottom>
          Dank voor uw bestelling bij Vuurwerk Bruchem!
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handlePrint}>
        Print Afhaalbewijs
      </Button>
    </Box>
  );
};
export default Confirmation;