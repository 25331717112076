import { useTheme } from "@emotion/react";
import { Box, Typography, IconButton } from "@mui/material";
import { Facebook, Instagram, YouTube, WhatsApp } from "@mui/icons-material";
import { shades } from "../../theme";

function Footer() {
  const currentYear = new Date().getFullYear();

  const {
    palette: { neutral },
  } = useTheme();

  const contactNumber = "0418700511";
  const address = "Dorpsstraat 20, 5314 AD Bruchem";
  const mapsLink =
    "https://www.google.com/maps?q=Dorpsstraat+20,5314+AD+Bruchem";

  const openingHours = [
    "28 december: 09:00 - 21:00",
    "30 december: 09:00 - 21:00",
    "31 december: 09:00 - 17:00",
  ];

  return (
    <Box marginTop="70px" padding="40px 0" backgroundColor={neutral.light}>
      <Box
        width="100%"
        margin="auto"
        maxWidth="80%"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box className="desktop-column" style={{ flexBasis: "25%" }}>
          <Typography
            variant="h5"
            fontWeight="normal"
            mb="20px"
            color={shades.secondary[500]}
          >
            VUURWERK BRUCHEM
          </Typography>
          <div className="desktop-text">
            Het beste vuurwerk in de Bommelerwaard voor een spectaculaire
            jaarwisseling! Laat de nacht oplichten met onze adembenemende
            collectie, zorgvuldig samengesteld om je te betoveren. Kies voor
            vuurwerk dat de lucht verlicht met heldere kleuren en maak van dit
            oud & nieuw een onvergetelijk feest! Vuurwerk Bruchem is de grootste
            in de regio, met ruime parkeergelegenheid en deskundig advies om
            jouw viering echt bijzonder te maken.
          </div>
        </Box>

        <Box className="desktop-column" style={{ flexBasis: "25%" }}>
          <Typography variant="h5" mb="20px">
            Openingstijden
          </Typography>
          <ul style={{ paddingInlineStart: "0" }}>
            {openingHours.map((time, index) => (
              <li key={index}>{time}</li>
            ))}
          </ul>
        </Box>

        <Box className="desktop-column" style={{ flexBasis: "25%" }}>
          <Typography variant="h5" mb="20px">
            Volg ons!
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <IconButton
              aria-label="Instagram"
              href="https://www.instagram.com/vuurwerkbruchem/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </IconButton>
            <IconButton
              aria-label="Facebook"
              href="https://www.facebook.com/vuurwerkbruchem.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </IconButton>
            <IconButton
              aria-label="YouTube"
              href="https://www.youtube.com/@RubroVuurwerkNL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTube />
            </IconButton>
            <IconButton
              aria-label="WhatsApp"
              href="https://wa.me/31616175368"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
            </IconButton>
          </div>
        </Box>

        <Box className="desktop-column" style={{ flexBasis: "25%" }}>
          <Typography variant="h5" mb="20px">
            Contact
          </Typography>
          <Typography variant="body1" mb="10px">
            Telefoon: {contactNumber}
          </Typography>
          <Typography variant="body1" sx={{ wordWrap: "break-word" }} mb="10px">
            Adres: {address}
          </Typography>
          <Typography variant="body1">
            <a href={mapsLink} target="_blank" rel="noopener noreferrer">
              Route op maps
            </a>
          </Typography>
          <Typography variant="body1">
          <a href="/verkoopvoorwaarden">
              Verkoopvoorwaarden
            </a>
          </Typography>
          <Typography variant="body1">
            <a href="/privacy-policy">
              Privacy policy
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        width="100%" // Volle breedte
        textAlign="center" // Centreer de tekst
        paddingTop="20px" // Ruimte aan de bovenkant
      >
<Typography variant="body2" color="textSecondary">
  © {currentYear} {" Vuurwerk Bruchem & "}
  <a href="https://www.martheijnen.com" target="_blank" rel="noopener noreferrer">
    <b>Mart Heijnen</b>
  </a>
  {" - "}
  <a href="https://www.fireworkflow.com" target="_blank" rel="noopener noreferrer">
    FireWorkFlow
  </a>
  {" : het complete vuurwerkbeheersysteem voor vuurwerkverkoop"}
</Typography>


      </Box>
      <style jsx>
        {`
          @media (max-width: 768px) {
            .desktop-text {
              display: none;
            }
            .desktop-column {
              flex-basis: 100%;
            }
          }
        `}
      </style>
    </Box>
  );
}

export default Footer;
