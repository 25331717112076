import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Link,
} from "@mui/material";
import { useAuth } from "../AuthContext";
import { fetcher2 } from '../api'; // Gebruik fetcher2 voor alle API-aanroepen

const OrdersPage = () => {
  const { userData } = useAuth();
  const [userOrders, setUserOrders] = useState([]);
  const [userWebshopOrders, setUserWebshopOrders] = useState([]); // Voeg een state toe voor webshop-orders
  const [showAllOrders, setShowAllOrders] = useState(false);
  const getDayOfWeek = (dateString) => {
    const daysOfWeek = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"];
    const dateParts = dateString.split('-'); // verwacht formaat: DD-MM-YYYY
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    return daysOfWeek[date.getDay()];
  };
  useEffect(() => {
    if (userData) {
      // Haal de orders op
      fetcher2(`orders?filters[email][$eq]=${userData.email}`)
      .then((orders) => {
          // Filter alleen de "betaalde" orders
          const paidOrders = orders.data.filter(
            (order) => order.attributes.stripePaymentStatus === "paid"
          );
          const sortedOrders = paidOrders.sort((a, b) =>
            new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
          );
          setUserOrders(sortedOrders);
        })
        .catch((error) => console.error("Error fetching orders:", error));

      // Haal ook de webshop-orders op
      fetcher2(`webshop-orders?filters[email][$eq]=${userData.email}`)
        .then((webshopOrders) => {
          // Filter alleen de "completed" webshop-orders
          const completedWebshopOrders = webshopOrders.data.filter(
            (webshopOrder) => webshopOrder.attributes.paymentStatus === "completed"
          );
          const sortedWebshopOrders = completedWebshopOrders.sort((a, b) =>
            new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
          );
          setUserWebshopOrders(sortedWebshopOrders);
        })
        .catch((error) => console.error("Error fetching webshop-orders:", error));
    }
  }, [userData]);

  return (
    <Container component="main" maxWidth="md" style={{ marginTop: "64px" }}>
      <div className="paper">
        <Typography variant="h5" component="div">
          Mijn Bestellingen
        </Typography>
        <Box mt={2}>
          {/* Toon de orders */}
          {userOrders && userOrders.length > 0 ? (
        (showAllOrders ? userOrders : userOrders.slice(0, 4)).map((order) => {
          // Bereken fullDate en dayOfWeek voor elke order
          const fullDate = `${order.attributes.pickupDate}-12-2024`;
          const dayOfWeek = getDayOfWeek(fullDate);

          return (
                <Card key={order.id} style={{ marginBottom: "20px" }}>
                  {/* Inhoud van de orderkaart */}
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Bestelling Nummer: {order.id}
                    </Typography>
                    <Typography color="textSecondary">
                      Datum van bestelling:{" "}
                      {new Date(order.attributes.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography color="textSecondary">
                      Totaalbedrag: €
                      {order.attributes.products.reduce(
                        (total, product) => total + product.price * product.count,
                        0
                      )}
                    </Typography>
                    <Typography color="textSecondary">
                Afhaaldag: {dayOfWeek} {fullDate}
              </Typography>
                    <Typography color="textSecondary">
                      Afhaaltijd: {order.attributes.pickupTimeSlot} uur
                    </Typography>
                    <Box mt={2}>
                      <Link
                        href={order.attributes.successUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Meer details en afhaalbewijs
                      </Link>
                    </Box>
                  </CardContent>
                  {/* Acties van de orderkaart */}
                  <CardActions>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Status:{" "}
                      {order.attributes.stripePaymentStatus === "paid"
                        ? "Betaald"
                        : "Niet betaald"}
                    </Typography>
                  </CardActions>
                  </Card>
          );
        })
      ) : (
        <Typography color="textSecondary">
              Geen betaalde bestellingen gevonden.
            </Typography>
          )}
          {userOrders.length > 4 && !showAllOrders && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAllOrders(true)}
            >
              Meer
            </Button>
          )}
        </Box>
        <Box mt={4}>
          {/* Toon de webshop-orders */}
          {userWebshopOrders && userWebshopOrders.length > 0 ? (
            (showAllOrders ? userWebshopOrders : userWebshopOrders.slice(0, 4)).map(
              (webshopOrder) => (
                <Card key={webshopOrder.id} style={{ marginBottom: "20px" }}>
                  {/* Inhoud van de webshop-orderkaart */}
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Webshop Order Nummer: {webshopOrder.id}
                    </Typography>
                    <Typography color="textSecondary">
                      Datum van bestelling:{" "}
                      {new Date(webshopOrder.attributes.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography color="textSecondary">
                      Totaalbedrag: €{webshopOrder.attributes.totalPrice}
                    </Typography>
                    <Typography color="textSecondary">
                      Afhaaldag: {webshopOrder.attributes.pickupDate} 
                    </Typography>
                    <Typography color="textSecondary">
                      Afhaaltijd: {webshopOrder.attributes.pickupTime} uur 
                    </Typography>
                    <Box mt={2}>
                      <Link
                        href={webshopOrder.attributes.successUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Meer details en afhaalbewijs
                      </Link>
                    </Box>
                  </CardContent>
                  {/* Acties van de webshop-orderkaart */}
                  <CardActions>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Status:{" "}
                      {webshopOrder.attributes.paymentStatus === "completed"
                        ? "Betaald"
                        : "Niet betaald"}
                    </Typography>
                  </CardActions>
                </Card>
              )
            )
          ) : (
            <Typography color="textSecondary">
              Geen voltooide webshop-orders gevonden.
            </Typography>
          )}
          {userWebshopOrders.length > 4 && !showAllOrders && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAllOrders(true)}
            >
              Meer
            </Button>
          )}
        </Box>
      </div>
    </Container>
  );
};

export default OrdersPage;
