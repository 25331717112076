import React, { useEffect, useState } from "react";
import MainCarousel from "./MainCarousel";
import ShoppingList from "./ShoppingList";
import Subscribe from "./Subscribe";
import { Helmet } from 'react-helmet';

const Home = () => {
  const [isFadingIn, setIsFadingIn] = useState(true);

  useEffect(() => {
    setIsFadingIn(false);
  }, []);

  return (
    <div className={`home-wrapper ${isFadingIn ? "fade-in" : ""}`} style={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>Vuurwerk Bruchem - Ontdek ons Assortiment</title>
        <meta name="description" content="Welkom bij Vuurwerk Bruchem. Blader door ons uitgebreide assortiment vuurwerk. Veiligheid en kwaliteit gegarandeerd." />
        <meta name="keywords" content="vuurwerk, Bruchem, vuurwerk kopen, veilig vuurwerk, kwaliteitsvuurwerk, nieuwjaar" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Store",
            "name": "Vuurwerk Bruchem",
            "description": "Welkom bij Vuurwerk Bruchem. Blader door ons uitgebreide assortiment vuurwerk. Veiligheid en kwaliteit gegarandeerd.",
            "url": "https://www.vuurwerkbruchem.com",
            "sameAs": [
              "https://www.facebook.com/vuurwerkbruchem.nl",
              "https://www.instagram.com/vuurwerkbruchem"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dorpsstraat 20",
              "addressLocality": "Bruchem",
              "postalCode": "5314 AE",
              "addressCountry": "NL"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "telephone": "[+31-418700511]",
              "email": "info@vuurwerkbruchem.nl"
            }
          }
          `}
        </script>
      </Helmet>
      <MainCarousel />
      <ShoppingList />
      <Subscribe />
    </div>
  );
};

export default Home;